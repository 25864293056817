import React, { Component } from "react"
import DualRingSpinner from "./DualRingSpinner"
import axios from "axios"
import config from "../config.json"
import { Container, Row, Col, Alert } from "reactstrap"

class ReportComponent extends Component {
	
	token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VybmFtZSI6Im5pc2hrYXIiLCJDbGllbnRJRCI6M30.NepwmHVavfyJWtauWmCyPqtCTYarBY_u5cIKrF26Aw4";
	DATE_RANGE_LIMIT = 3;

	state = {
		busy: true,
		errorMsg: "",
		infoMsg: "",
		tripData: null
	}

	componentDidMount() {
		this.requestData();
	}

	// componentWillReceiveProps(newProps) {
	// 	console.log("componentWillReceiveProps");
	// 	console.log(newProps);;

	// 	const oldProps = this.props
	// 	if(oldProps.trackerid !== newProps.trackerid || 
	// 	   oldProps.startDate !== newProps.startDate || 
	// 	   oldProps.endDate !== newProps.endDate) {

	// 		this.setState(s => {
	// 			return Object.assign(s, {busy: true, errorMsg: "" });
	// 		});

	// 	    this.requestData();
	// 	}
	// }

	formatStartDate(d) {
		let month = (d.getMonth()+1 < 10)? `0${d.getMonth()+1}`: `${d.getMonth()+1}`
		let day = (d.getDate() < 10)? `0${d.getDate()}`:`${d.getDate()}`;
		return `${d.getFullYear()}-${month}-${day} 00:00:00`;
	}

	formatEndDate(d) {
		let month = (d.getMonth()+1 < 10)? `0${d.getMonth()+1}`: `${d.getMonth()+1}`
		let day = (d.getDate() < 10)? `0${d.getDate()}`:`${d.getDate()}`;
		return `${d.getFullYear()}-${month}-${day} 23:59:59`;
	}

	countDays = (date1, date2) => {
	    // The number of milliseconds in one day
	    var ONE_DAY = 1000 * 60 * 60 * 24;

	    // Convert both dates to milliseconds
	    var date1_ms = date1.getTime();
	    var date2_ms = date2.getTime();

	    // Calculate the difference in milliseconds
	    var difference_ms = Math.abs(date1_ms - date2_ms);

	    // Convert back to days and return
	    return Math.round(difference_ms/ONE_DAY);
	}

	requestData() {

		let {trackerid, startDate, endDate} = this.props;

		if (!(startDate instanceof Date)) {
			this.setState(s => Object.assign(s, { busy: false, error: true, errorMsg: "Invalid date range", infoMsg: "", tripData: null }));
			return;
		}

		if (!(endDate instanceof Date)) {
			this.setState(s => Object.assign(s, { busy: false, error: true, errorMsg: "Invalid date range", infoMsg: "", tripData: null }));
			return;
		}

		if (startDate > endDate) {
			this.setState(s => Object.assign(s, { busy: false, error: true, errorMsg: "End date occurs before start date", infoMsg: "", tripData: null }));
			return;
		}

		if(this.countDays(startDate, endDate) > this.DATE_RANGE_LIMIT){
			this.setState(s => Object.assign(s, { busy: false, error: true, errorMsg: `Selected date range exceeds limit of ${this.DATE_RANGE_LIMIT} days.`, infoMsg: "", tripData: null }));
			return;
		}

		var url = `${config.apiEndpoint}/history2?trackerid=${trackerid}&from=${encodeURI(this.formatStartDate(startDate))}&to=${encodeURI(this.formatEndDate(endDate))}`;

		//let url = `${config.apiEndpoint}/history2?trackerid=0814517787%20&from=2019-01-04%2000:00:00&to=2019-01-04%2023:59:59`

		let me = this;

		axios.get(url, {
		 	headers: {
		 		Authorization: this.token
		 	}
		}).then(response => {
			let data = response.data;
			if(data.Success === true){
				if(data.NextAction === "retry"){
					setTimeout(this.requestData.bind(me), 5000);
					let info = (data.Errors && data.Errors.length > 0)? data.Errors[0] : "Busy processing report data, this may take a few minutes";
					this.setState(s => Object.assign(s, {busy: true, errorMsg: "", infoMsg: info, tripData: null}));
				} else {
					this.setState(s => Object.assign(s, {busy: false, errorMsg: "", infoMsg: "", tripData: data.Trips }));
				}
			} else {
				let error = (data.Errors && data.Errors.length > 0)? data.Errors[0]:"An error occurred retrieving report data." 
				this.setState(s => Object.assign(s, {busy: false, errorMsg: error, infoMsg: "", tripData: null}));
			}
		}).catch(err => {
			//console.log(err);
			this.setState(s => Object.assign(s, {busy: false, errorMsg: "Unable to retrieve report data.", infoMsg: "", tripData: null}));
		})
	}

	render() {
		return this.state.busy? 
					( <div style={{
				   			display: "flex",
				   			flexFlow: "column nowrap",
				   			flex: "1",
				   			alignItems: "center",
  	 						justifyContent: "center"
				   		}}>
				   		<DualRingSpinner />
				   		<div style={{
							   paddingTop: "10px"
						    }}>
							{this.state.infoMsg}
						</div>
				   	  </div>
			   		)
				: 
				(	 <> 
					 	{this.state.error?
							(
								<Alert variant="danger">{this.state.errorMsg}</Alert>
							)
					 	:
					 		(
					 			<Container fluid style={{ flex: 1, overflowY: "auto" }}>
					 				{this.state.tripData && this.state.tripData.length > 0?
					 					<>
					 						<ReportHeader />
					 						{this.state.tripData.map(Trip)}
					 					</>
					 				 :  <Alert variant="danger">No data found for date range.</Alert>
					 				}
					 			</Container>
					 		)
						}
					</>
				)
	}
}


const cleanTS = (ts) => ts.replace(" +0200 SAST", "")
const status = (i, tripLength) => {
	if(i === 0 && tripLength > 0) {
		return <div style={{backgroundColor: "green", color: "white", textAlign: "center"}}>Start</div>;
	}
	if(i === tripLength-1) {
		return  <div style={{backgroundColor: "red", color: "white", textAlign: "center"}}>Stop</div>;
	}
	return <div style={{backgroundColor: "light-green", color: "white", textAlign: "center"}}>Moving</div>;
}
const round0 = (d) => {
	return Math.round(d);
}
const round1 = (d) => {
	return (d).toFixed(1);
}

const reportHeaderStyle = {
	fontWeight: "bold"
}

const ReportHeader = () => (
	<Row style={reportHeaderStyle}>
		<Col sm={2}>Timestamp</Col>
		<Col sm={1}>Status</Col>
		<Col sm={7}>Address</Col>
		<Col sm={1}>Speed</Col>
		<Col sm={1}>Distance</Col>
	</Row>
)

const Trip = (t) => {
	let tripLength = t.LocationPoints.length;

	return t.LocationPoints.map((v,i) => (
		<Row key={`${v.StartTimestamp}${v.EndTimestamp}${v.SpeedKmph}`}>
			<Col sm={2}>{cleanTS(v.Timestamp)}</Col>
			<Col sm={1}>{status(i, tripLength)}</Col>
			<Col sm={7}>{v.Address}</Col>
			<Col sm={1}>{round0(v.SpeedKmph)} km/h</Col>
			<Col sm={1}>{round1(v.DistanceFromLastPoint)} km</Col>
		</Row>
	))
	
};

export default ReportComponent;

