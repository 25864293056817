import { AUTH_LOGIN_FAILED, ERRORS_CLEAR } from '../actions/actionTypes'

const initialState = {
    error: false,
    errorMessages: []
}

const errorsReducer = (state=initialState, action) => {
    switch(action.type) {
        case AUTH_LOGIN_FAILED:
            return {
                error: true,
                errorMessages: ['Login Failed']
            }
        case ERRORS_CLEAR:
            return {
                error: false,
                errorMessages: []
            }
        default:
            return state
    }
}

export default errorsReducer