import { AUTH_LOGIN_SUCCESS, AUTH_LOGIN_FAILED, AUTH_LOGOUT } from '../actions/actionTypes'
    
const initialState = {
    authenticated: false,
    user: null
}

const authReducer = (state=initialState, action) => {
    switch (action.type) {
        case AUTH_LOGIN_SUCCESS:
            return { authenticated: true, user: action.user }
        case AUTH_LOGIN_FAILED:
            return { authenticated: false, user: null }
        case AUTH_LOGOUT: // same as AUTH_LOGIN_FAILED
            return { authenticated: false, user: null }
        default:
            return state
    }
}

export default authReducer