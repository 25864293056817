import { AUTH_LOGIN_SUCCESS, AUTH_LOGIN_FAILED, AUTH_LOGOUT } from './actionTypes'
import { loadingStarted, loadingEnded } from './loadingActions'
import config from "../../config.json"
import axios from 'axios'

export const userLoginSuccess = (user) => ({
    type: AUTH_LOGIN_SUCCESS,
    user
})

export const userLoginFailure = () => ({
    type: AUTH_LOGIN_FAILED
})

export const userLoggedOut = () => ({
    type: AUTH_LOGOUT
})

export const initialiseAuthThunk = () => {
    return (dispatch) => {
        dispatch(loadingStarted())
		axios.get(`${config.apiEndpoint}/me`, {
			withCredentials: true,
			responseType: "json"
		}).then(res => {
    		const resp = res.data;
			//console.log("Refresh Auth", resp);
			dispatch(loadingEnded())
			if (resp.Success === true) {
				dispatch(userLoginSuccess(resp.Client))
			} else {
				dispatch(userLoginFailure())
			}
  		});
    }
}

export const logoutThunk = () => {
    return (dispatch/*, getState*/) => { 
        axios.post(`${config.apiEndpoint}/me/logout`, { },
        {
            withCredentials: true,
            responseType: "json"
        }).then(resp => {
            dispatch(userLoggedOut())
        });
    }
}
