import React, { useState } from 'react'
import { Container, Row, Col, Form, Button} from 'reactstrap'
import { useForm } from "react-hook-form"

import axios from "axios";
import config from "../config.json"

import { Navigate } from 'react-router-dom';

import './LoginPage.scss'
import clogo from '../images/csa-logo-clipped-rect.png'

const LoginPage = ({authenticated, authSuccess, startLoading, endLoading}) => {

	const [state, setState] = useState({ busy: false, errorMsg: "" })
	const { handleSubmit, register, formState: { errors } } = useForm()

	const onSubmit = (values) => {
		setState({ busy: true, errorMsg: "" })

		axios.post(`${config.apiEndpoint}/authenticate`, { 
			Username: values.username, Password: values.password 
		}, {
			withCredentials: true,
			responseType: "json"
		}).then(resp => {
			//endLoading()
			let data = resp.data
			if(data.Success === true) {
				setState({ busy: false, errorMsg: ""})
				authSuccess()
			} else {
				setState({ busy: false, errorMsg: data.Errors[0] })
			}
		}).catch(err => {
			//endLoading()
			setState({ busy: false, errorMsg: "Invalid credentials." })
		})
	}

	return (
		authenticated? <Navigate to={{ pathname: '/' }} /> : 
			<Container fluid className="login-page font-ctsa">
				<Row className="header">
					<Col xs={12} className="contact-details">
						<Container>
							<Row className="no-gutters">
								<Col xs="auto">Contact Center:</Col>
								<Col className="flex-grow-1">
									<a className="d-inline-block ml-2" href="tel:+27672473650">067 247 3650</a>
									<a className="d-none d-md-inline-block ml-2" href="tel:+27315352897">031 535 2897</a>
									<a className="d-none d-lg-inline-block ml-2" href="tel:+270677247365">0677 24 7 365</a>
								</Col>
								<Col xs="auto">
									<a className="d-none d-md-inline-block ml-2" href="mailto:info@cybertracsa.co.za">info@cybertracsa.co.za</a>
								</Col>
							</Row>
						</Container>
					</Col>
				</Row>
				<Row className="flex-grow-1 align-items-stretch">
					<Col lg={6} className="header-section">
						<Container className="outline">
							<Row className="welcome outline">
								<Col xs="auto" >
									<h3>Welcome to</h3>
									<div className="line"></div>
								</Col>
								<Col lg={"auto"}>
									<h2 className="text-center">CYBERTRACSA</h2>
								</Col>
							</Row>
							<Row className="outline">
								<Col className="blurb text-center">Intelligent Tracking Technology</Col>
							</Row>
							<Row className="outline">
								<Col xs={12} className="text-right d-none d-lg-block">
									<img src={clogo} alt="cybertracsa logo" />
								</Col>
							</Row>
						</Container>
					</Col>


					<Col lg={6} className="login-section">
						<Container className="outline">
							<Row>
								<Col xs={12} md={{size: 8, offset:2}} lg={{size: 8, offset:2}}>
									<h2 className="d-none d-lg-block">Login</h2>
									<Form onSubmit={handleSubmit(onSubmit)}>
										<input className="form-control" type="text" placeholder="Username" name="username" 
											{...register('username', { required: true })} 
											/>
										<div className="val-error">{errors.username? errors.username.message : <span>&nbsp;</span> }</div>

										
										<input className="form-control" type="password" placeholder="Password" name="password"
											{...register('password', { required: true })}
											/>
										<div className="val-error">{errors.password? errors.password.message : <span>&nbsp;</span> }</div>

										<Button color="primary" type="submit">Login</Button>
									</Form>
								</Col>
							</Row>
							<Row>
								<Col className="val-error text-center" xs={12}>
									<span>{state.errorMsg? state.errorMsg : <span>&nbsp;</span> }</span> 
								</Col>
								<Col xs={12} className="text-center d-lg-none">
									<img src={clogo} alt="cybertracsa logo" />
								</Col>
							</Row>
						</Container>
					</Col>
				</Row>
			</Container>
	)
}


export default LoginPage;
