export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS'
export const AUTH_LOGIN_FAILED = 'AUTH_LOGIN_FAILED'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'

export const ERRORS_CLEAR = 'ERRORS_CLEAR'

export const LOADING_START = 'LOADING_START'
export const LOADING_END = 'LOADING_END'

export const SELECTED_VEHICLE_CHANGE = 'SELECTED_VEHICLE_CHANGE'
