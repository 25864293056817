import React, { useState } from "react"
import { Link } from 'react-router-dom'
import "./TabBar.css"

const TabBar = ({authenticated, initialActive}) => {

	const [active, setActive] = useState(initialActive)

	return (
		<div className="tabBarWrapper" style={{ display: authenticated? "block":"none" }}>
			<Link className={active === "map"? "tabBarLink active":"tabBarLink"} onClick={ () => { setActive("map") }} to="/">Map</Link>
			<Link className={active === "reports"? "tabBarLink active":"tabBarLink"} onClick={ () => { setActive("reports") }} to="/reports">Reports</Link>
			{/* <Link className={active === "logs"? "tabBarLink active":"tabBarLink"} onClick={ () => { setActive("logs") }} to="/logs">Logs</Link>
			<Link className={active === "profile"? "tabBarLink active":"tabBarLink"} onClick={ () => { setActive("profile") }} to="/profile">Profile</Link> */}
		</div>
	)
};

export default TabBar;