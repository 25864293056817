import React from "react"
import { Navigate } from 'react-router-dom'
import VehicleSelector from './VehicleSelector'
import RealtimeLocationComponent from "./RealtimeLocationComponent.js"
import { connect } from 'react-redux'

const MapPage = ({authenticated, vehicle}) => {

	if (!authenticated) {
		return <Navigate to={{ pathname: '/login' }} />
	}

	return (
		<div className="Page">
			<VehicleSelector />
			{vehicle && <RealtimeLocationComponent trackerid={vehicle.TrackerID} />}
		</div>
	)
}

const mapStateToProps = (state, ownProps) => {
	return {
		authenticated: state.auth.authenticated,
		vehicle: state.selectedVehicle
	}
}

export default connect(mapStateToProps)(MapPage)

