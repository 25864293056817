import React from "react";

const Footer = () => (
	<div id="Footer"
		style={{
			backgroundColor: "#001628",
			color: "white",
			padding: "0.3rem",
			display: "flex",
			flexDirection: "row-reverse",
		}}>
		&copy; CYBERTRACSA {new Date().getFullYear()}
	</div>
)

export default Footer;