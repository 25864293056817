import React from 'react'
//import logo from "../images/cybertrac-logo-300-logo-only-white-red.png"
//import logo from '../images/nish-logo.png'
import logo from '../images/cybertracsa-new.png'

import ProfileDropdown from './ProfileDropdown'

// backgroundColor: "#001628",
// blue background #007bff

const Header = ({loggedIn, client, onLogOut}) => (
	<div id="Header"
		style={{
			//backgroundColor: "#007bff",
			padding: "1rem",
			display: loggedIn?"flex":"none",
			flexFlow: "row nowrap",
			justifyContent: "space-between"
		}}>
		<img src={logo} alt="logo"/>
		{loggedIn? <ProfileDropdown user={client} onLogOut={onLogOut} />: null }
	</div>
);

export default Header;
