import React from "react"
import "./App.css"
import Header from "./components/Header"
import Footer from "./components/Footer"
import TabBar from "./components/TabBar"
import EmptyPage from "./components/EmptyPage"
import LoginPage from "./components/LoginPage"
import MapPage from "./components/MapPage"
import ProfilePage from "./components/ProfilePage"
import LineProgress from './components/LineProgress'
import ReportsPage from "./components/ReportsPage"
import {
  BrowserRouter,
  Route,
  Routes
} from 'react-router-dom';
import { connect } from 'react-redux'
import { initialiseAuthThunk, logoutThunk } from './redux/actions/authActions'
import { loadingStarted, loadingEnded } from './redux/actions/loadingActions'

class App extends React.Component {
	componentDidMount() {
		this.props.onRefreshAuth()
	}
	render() {
		const { authenticated, user, onLogOut, loading, onRefreshAuth, startLoading, endLoading } = this.props
		return (
			<div className="App">
				<LineProgress isAnimating={loading} />
			  	<BrowserRouter>
					
					<Header loggedIn={authenticated} client={user} onLogOut={onLogOut}  />
					{ loading? (
						<EmptyPage /> 
				) : (
						<>
							<TabBar authenticated={authenticated} initialActive="map" />
							<Routes>
							<Route path='/login'
								   element={<LoginPage authenticated={authenticated} authSuccess={onRefreshAuth} startLoading={startLoading} endLoading={endLoading} />}
							/>
							<Route exact path='/' element={<MapPage />} />
							<Route path="/profile" 
								   element={<ProfilePage authenticated={authenticated} /> } />
							<Route path="/reports" 
							  	   element={<ReportsPage /> } />
							</Routes>
						</>
					)}
					<Footer />
					
		      	</BrowserRouter>
		    </div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		authenticated: state.auth.authenticated,
		loading: state.loading,
		user: state.auth.user
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		onLogOut: ()=>{ dispatch(logoutThunk()) },
		onRefreshAuth: ()=>{ dispatch(initialiseAuthThunk()) },
		startLoading: () => { dispatch(loadingStarted()) },
		endLoading: () => { dispatch(loadingEnded()) }
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(App);




// class App extends Component {

// 	state = {
// 		loading: true,
// 		loggedIn: false,
// 		client: null,
// 		currentTab: "map"
// 	}

// 	componentDidMount() {
// 		this.refreshData()
// 	}

// 	refreshData() {

// 		// let resp = {
// 		// 	  "Success": true,
// 		// 	  "Errors": null,
// 		// 	  "Client": {
// 		// 	    "ClientID": 3,
// 		// 	    "Username": "nishkar",
// 		// 	    "Fullname": "Nishkar Ramautar",
// 		// 	    "IsAdmin": false,
// 		// 	    "Vehicles": [
// 		// 	      {
// 		// 	        "Registration": "ND297743",
// 		// 	        "Make": "VOLKSWAGEN",
// 		// 	        "Model": "Polo 1.2 TSI Highline",
// 		// 	        "Year": 2015,
// 		// 	        "Color": "WHITE",
// 		// 	        "ChassisNumber": "AAVZZZGRZFU058631",
// 		// 	        "TrackerID": "0604961402",
// 		// 	        "ClientName": "Nishkar Ramautar",
// 		// 	        "ClientID": 3
// 		// 	      }
// 		// 	    ]
// 		// 	  }
// 		// 	};

// 		// this.setState(state => {
//   //   			return Object.assign(state, { loading: false, loggedIn: resp.Success, client: resp.Client });
//   //   		})

// 		axios.get(`${config.apiEndpoint}/me`,{
// 			withCredentials: true,
// 			responseType: "json"
// 		}).then(res => {
//     		const resp = res.data;
//     		//console.log(resp);
//     		this.setState(state => {
//     			return Object.assign(state, { loading: false, loggedIn: resp.Success, client: resp.Client });
//     		})
//   	});
// 	}

// 	authSuccess() {
// 		this.setState(state => {
//     		return Object.assign(state, { loading: true });
//     	});
//     	this.refreshData();
// 	}

// 	authLogout() {
// 		this.setState(state => {
//     		return Object.assign(state, { loading: true });
//     	});
// 		axios.post(`${config.apiEndpoint}/me/logout`, { },
// 			{
// 				withCredentials: true,
// 				responseType: "json"
// 			}).then(resp => {
// 				this.refreshData();
// 			});
// 	}

// 	render() {
// 		return (
// 			<div className="App">
// 			  <Router>		
// 			      <Header loggedIn={this.state.loggedIn} client={this.state.client} onLogOut={this.authLogout.bind(this)}  />
// 			      {this.state.loading? (
// 			      	<EmptyPage /> 
// 			      ) : (
// 			      		<>
// 			      			<TabBar loggedIn={this.state.loggedIn} initialActive="map" />
// 			      			<Route path="/profile" component={ProfilePage} />

// 			      			<Route path='/reports'
// 								   render={(props) => <ReportsPage {...props} 
// 																	loggedIn={this.state.loggedIn} 
// 																	vehicles={this.state.client && this.state.client.Vehicles} 
// 																	client={this.state.client} />}
// 							/>

// 			      			<Route path='/logs'
// 								   render={(props) => <LogsPage {...props} loggedIn={this.state.loggedIn} vehicles={this.state.client && this.state.client.Vehicles} />}
// 							/>

// 			      			<Route path='/login'
// 								   render={(props) => <LoginPage {...props} loggedIn={this.state.loggedIn} authSuccess={this.authSuccess.bind(this)} />}
// 							/>

// 							<Route exact path='/'
// 								   render={(props) => <MapPage {...props} loggedIn={this.state.loggedIn} vehicles={this.state.client && this.state.client.Vehicles} />}
// 							/>
// 						</>
// 			      )}
// 			      <Footer />
// 		      </Router>
// 		    </div>
// 		)
// 	}

// }


// const App = ({ authenticated, loading, user, onAuthSuccess, onLogOut, dispatch}) => {

// 	useEffect(()=>{
// 		dispatch(loadingStarted())
// 		axios.get(`${config.apiEndpoint}/me`,{
// 			withCredentials: true,
// 			responseType: "json"
// 		}).then(res => {
//     		const resp = res.data;
// 			console.log(resp);
// 			dispatch(loadingEnded())
// 			if (resp.Success === true) {
// 				dispatch(userLoginSuccess(resp.Client))
// 			} else {
// 				dispatch(userLoginFailure())
// 			}
//   		});
// 	}, [authenticated]);


// 	return (
// 		<div className="App">
// 			  <Router>		
// 			      <Header loggedIn={authenticated} client={user} onLogOut={onLogOut}  />
// 			      { loading? (
// 			      		<EmptyPage /> 
// 			      ) : (
// 			      		<>
// 			      			<TabBar loggedIn={authenticated} initialActive="map" />
			      			
// 			      			<Route path='/login'
// 								   render={(props) => <LoginPage {...props} loggedIn={authenticated} authSuccess={onAuthSuccess} />}
// 							/>
// 							<Route path="/" loggedIn={authenticated} component={ProfilePage} />
// 						</>
// 			      )}
// 			      <Footer />
// 		      </Router>
// 		    </div>
// 	)

// }


