import { SELECTED_VEHICLE_CHANGE } from '../actions/actionTypes'

const selectedVehicleReducer = (state = null, action) => {
    switch(action.type) {
        case SELECTED_VEHICLE_CHANGE:
            return action.vehicle
        default:
            return state
    }
}

export default selectedVehicleReducer