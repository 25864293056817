import React, { Component, Fragment } from "react"
import HEREMap, { Marker } from 'here-maps-react'
import { Container, Row, Col } from 'reactstrap'
import DualRingSpinner from "./DualRingSpinner"
import axios from "axios"
import config from "../config.json"

class RealtimeLocationComponent extends Component {
	
	intervalTimeMS = 20000;

	token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VybmFtZSI6Im5pc2hrYXIiLCJDbGllbnRJRCI6M30.NepwmHVavfyJWtauWmCyPqtCTYarBY_u5cIKrF26Aw4"

	state = {
		dataAvailable: false,
		error: false,
		errorMsg: "",
		lat: -29,
		lng: 30,
		address: "",
		lastUpdateTime: "",
		speedKmph: 0,
		ignitionOn: false,
		placeMarker: false,
		refreshing: true
	}

	intervalId = null;

	componentWillReceiveProps(newProps) {
		const oldProps = this.props
		if(oldProps.trackerid !== newProps.trackerid) {

			this.setState(s => {
				return Object.assign(s, {dataAvailable: false, placeMarker: false});
			});

			if (this.intervalId) {
				clearInterval(this.intervalId);
				console.log("Auto Refresh off: " + this.intervalId);
			}
		    this.refreshData(newProps.trackerid);
			this.intervalId = setInterval(() => { 
				console.log("Auto refresh");
				this.refreshData(newProps.trackerid); 
			}, this.intervalTimeMS);
		}
	}

	refreshData(trackerid) {
		this.setState(currentState => {
			return Object.assign(currentState, {refreshing: true, placeMarker: false});
		});
		axios.get(`${config.apiEndpoint}/locate?trackerid=${trackerid}`, {
			headers: {
				Authorization: this.token
			}
		}).then(response => {
			let data = response.data;

			this.setState(currentState => {
				return Object.assign(currentState, {placeMarker: false});
			});

			if (data.Success === true) {
				let newState = {
					dataAvailable: true,
					error: false,
					errorMsg: "",
					address: data.Location.GPSLocationMinutes,
					lastUpdateTime: data.Location.Timestamp,
					ignitionOn: data.Location.EngineStatus === "ON",
					speedKmph: data.Location.SpeedKmph,
					refreshing: false
				};

				let coords = data.Location.GPSLocationDegrees.split(",");
				newState.lat = (+coords[0]);
				newState.lng = (+coords[1]);

				// //////// // / / / / / / / / / /  / / / / / / / / /  / / / / / / / / / / / / / / / / /
				// We do 2 setStates here because the marker isnt being shown when I only do one??
				// //////// // / / / / / / / / / /  / / / / / / / / /  / / / / / / / / / / / / / / / / /

				this.setState(currentState => {
					return Object.assign(currentState, newState);
				});

				axios.get(`${config.apiEndpoint}/reversegeo?coords=${data.Location.GPSLocationDegrees}`,{
					headers: {
						Authorization: this.token
					}
				}).then(reverseResponse => { 
					let reverseData = reverseResponse.data;
					if(reverseData.Success === true){
						this.setState(currentState => {
							return Object.assign(currentState, {address: reverseData.Address, placeMarker: true});
						});
					} else {
						this.setState(currentState => {
							return Object.assign(currentState, { address: `${data.Location.GPSLocationDegrees} Address lookup was unsuccessful.`, placeMarker: true });
						});
					}
				}).catch(reverseErr => {
					this.setState(currentState => {
						return Object.assign(currentState, { address: `${data.Location.GPSLocationDegrees} Address lookup failed.`, placeMarker: true });
					});
				});

			} else {
				// Error, what do we do with it?
			}
		}).catch(err => {
			//console.log(err);
			this.setState(currentState => {
				return Object.assign(currentState, {error: true, errorMsg: "Unable to refresh data."})
			});
		});
	}

	componentDidMount() {
		this.refreshData(this.props.trackerid);
		this.intervalId = setInterval(() => { 
			console.log("Auto refresh");
			this.refreshData(this.props.trackerid); 
		}, this.intervalTimeMS);
	}

	componentWillUnmount() {
		if (this.intervalId) {
			clearInterval(this.intervalId);
			console.log("Auto Refresh off: " + this.intervalId);
		}
	}

	onOff = (ignitionOn, speed) => (
		<span>
			<span style={{
				display: "inline-block",
				color: "white",
				backgroundColor: ignitionOn? "green":"red",
			}}>{ignitionOn? speed + " km/h" :" OFF "}</span>
			&nbsp;
		</span>
	)

	timestamp = (ts) => {
		let timeStamp = ts.replace(" +02 SAST", "");
		return (
			<span style={{ fontSize: "smaller" }}>{timeStamp}</span>
		)
	} 

	render() {

		return (
			<div style={{
				display: "flex",
				flexFlow: "column nowrap",
				flex: 1 }}>
				{this.state.dataAvailable? 
					<HEREMap
						apikey="fLZQzcO_0XHodV0egLVd306DYoUn6iY5njk-Lo40b-k"
				        center={{ lat: this.state.lat, lng: this.state.lng }}
				        zoom={15}
				        interactive={true}
				        secure={true}
				    >
				    	{this.state.placeMarker && <Marker lat={this.state.lat} lng={this.state.lng} />}
				    </HEREMap>
				   : 
				   	<div style={{
				   			display: "flex",
				   			flexFlow: "column nowrap",
				   			flex: "1",
				   			alignItems: "center",
  							justifyContent: "center"
				   		}}>
				   		<DualRingSpinner />
				   	</div>
				}
			    <div style={{
			    		padding: "2rem",
			    		backgroundColor: "#F8F6FA"
			    	}}>
			    	<Container fluid>
		    			{this.state.dataAvailable?
		    				<Fragment>
			    				<Row>
				    				<Col>
				    					<span>{this.onOff(this.state.ignitionOn, this.state.speedKmph)}</span>
				    					<span>{this.state.address}</span>
				    				</Col>
				    			</Row>
				    			<Row>
				    				<Col>
										{this.state.refreshing? <i>refreshing..</i> : <span>{this.timestamp(this.state.lastUpdateTime)}</span>}
				    				</Col>
				    			</Row>
				    		</Fragment>
		    			  : <Row>
		    			  		<Col><DualRingSpinner /></Col> 
		    			  	</Row>
		    			}
			    	</Container>
			    </div>
			</div>
		)
	}

}

export default RealtimeLocationComponent;


// {
//   "data": {
//     "Success": true,
//     "Errors": [],
//     "Location": {
//       "GPSLocationMinutes": "29° 44' 28.6008\" S 31° 2' 38.8422\" E",
//       "GPSLocationDegrees": "-29.741278,31.044123",
//       "EngineStatus": "ON",
//       "SpeedKmph": 116.676,
//       "DueCourse": 49.3,
//       "Timestamp": "2019-05-07 08:37:34 +02 SAST",
//       "Address": "",
//       "DistanceFromLastPoint": 0
//     }
//   },
//   "status": 200,
//   "statusText": "OK",
//   "headers": {
//     "content-type": "application/json"
//   },
//   "config": {
//     "transformRequest": {},
//     "transformResponse": {},
//     "timeout": 0,
//     "xsrfCookieName": "XSRF-TOKEN",
//     "xsrfHeaderName": "X-XSRF-TOKEN",
//     "maxContentLength": -1,
//     "headers": {
//       "Accept": "application/json, text/plain, */*",
//       "Authorization": "this.token"
//     },
//     "method": "get",
//     "url": "https://api.cybertracsa.co.za/locate?trackerid=0604961402"
//   },
//   "request": {}
// }
