import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

const ProfileDropdown = ({user, onLogOut}) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen(prevState => !prevState);

    return (
        <Dropdown color="secondary" isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle caret>
                { user.Fullname }
            </DropdownToggle>
            <DropdownMenu end>
                <DropdownItem onClick={onLogOut}>Log Out</DropdownItem>
            </DropdownMenu>
        </Dropdown>
  );
}

export default ProfileDropdown;