import React from "react"
import { Navigate } from 'react-router-dom'
import VehicleSelector from './VehicleSelector'

let ProfilePage = ({authenticated}) => {
	if (!authenticated) {
		return <Navigate to={{ pathname: '/login' }} />
	}

	return ( 
		<div className="Page">
			<VehicleSelector />
		</div>
	)
}

export default ProfilePage

