import React from "react";
import "./DualRingSpinner.css";
import DualRingSpinner from "./DualRingSpinner";

const EmptyPage = () => (
	<div className="Page"
		style={{
   			display: "flex",
   			flexFlow: "column nowrap",
   			flex: "1 1",
   			alignItems: "center",
			justifyContent: "center"
   		}}>
		<DualRingSpinner />
	</div>
)

export default EmptyPage;