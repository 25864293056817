import React from 'react'
import { Button, Spinner } from 'reactstrap'
import axios from 'axios'

class DownloadReportButton extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            enabled: true,
            busy: false,
            excel_busy: false
        }
    }

    buildTripReportRequest({client, vehicle, startDate, endDate}) {

        if(!(client && vehicle)){
            console.log("c||v empt");
            return null;
        }

        return {
            "trackerId" : vehicle.TrackerID,
            "from" : formatStartDate(startDate),
            "to" : formatEndDate(endDate),
            "clientName": client.Fullname,
            "clientId": `${client.ClientID}`,
            "vehicleDescr": `${vehicle.Model} ${vehicle.Make} (${vehicle.Year},${vehicle.Color})`,
            "vehicleReg": vehicle.Registration,
            "vinNo": vehicle.ChassisNumber,
            "fitmentDate": "2016-06-18 12:41 PM",
            "reportDate": getReportDate()
        };

// {
//     "trackerId": "0604961402",
//     "from": "2019-08-31",
//     "to": "2019-08-31",
//     "clientName": "Nishkar Ramautar",
//     "clientId": "-1",
//     "vehicleDescr": "Corsa Utility CHEVROLET (2010,WHITE)",
//     "vehicleReg": "ND802019",
//     "vinNo": "N/A",
//     "fitmentDate": "2016-06-18 12:41 PM",
//     "reportDate": "Sat Aug 31 2019 12:54:04 PM"
// }


        // {
//     "client": {
//       "ClientID": -1,
//       "Username": "nishkarr",
//       "Fullname": "Nishkar Ramautar",
//       "IsAdmin": true,
//       "Vehicles": [
//         {
//           "Registration": "ND203539",
//           "Make": "AUDI",
//           "Model": "A4",
//           "Year": 2008,
//           "Color": "silver",
//           "ChassisNumber": "WAUZZZ8E05A4450363",
//           "TrackerID": "0786976405",
//           "ClientName": "KARSON NAIDOO",
//           "ClientID": 1
//         },
//     "startDate": "2019-08-31T10:28:46.309Z",
//     "endDate": "2019-08-31T10:28:46.309Z"

    }

 // {
//     "Success": true,
//     "SignedUrl": [
//         "https://storage.googleapis.com/cybertracsa01.appspot.com/0604961402-2019-08-31-2019-08-31-EXCL?GoogleAccessId=cybertracsa01%40appspot.gserviceaccount.com&Expires=1580872712&Signature=E7Z6DCDikZiY14n5HIreVnoEt0CkAJ004mUbs8XwB0aNNbfrOP1w2daxikwI2MtWUHNovtdv2B69Dcc8XnytGSmViWYdR7iYlCaxZECWmoQ%2Bty2jk878lAfYFZalR0OydnvtNalt3bKL%2FCQqYwK%2Be2PTuBjKpZ5ud833xEzMPWl1alUY7cInov%2FnM5%2BrR1qUlSjQCi%2FWycP2E%2FM1mz6Xna6e8ChOW3k1WlGpAN7rEswgsKjr7iUcfwe3NQbm4h98obEAs0de24%2FDMrRsYOMH39UIgjakGcnBtXZgxNa5ihYyCEQ7DBQAuRzYTvkDe%2FowdxmXGwTVEJlsImhvqh45pw%3D%3D&response-content-type=application%2Fvnd.ms-excel&response-content-disposition=attachment%3B%20filename%3D%22Report-2019-08-31-2019-08-31.xlsx%22"
//     ]
// }


    // downloadFileExcel(fileKey) {
    //     const serveFileHtmlUrl = `https://us-central1-cybertracsa01.cloudfunctions.net/tripreportserve?fileRequestKey=${fileKey}`
    //     //let cyberpdfUrl = `https://us-central1-cybertracsa01.cloudfunctions.net/cyberpdf?URL1=${encodeURIComponent(serveFileHtmlUrl)}`
    //     const link = document.createElement('a');
    //     link.href = serveFileHtmlUrl;
    //     link.setAttribute('target', '_blank');
    //     document.body.appendChild(link);
    //     link.click();
    // }


    renderAndDownloadPDFFile(fileKey) {
        const serveFileHtmlUrl = `https://us-central1-cybertracsa01.cloudfunctions.net/tripreportserve?fileRequestKey=${fileKey}`
        let cyberpdfUrl = `https://us-central1-cybertracsa01.cloudfunctions.net/cyberpdf?URL1=${encodeURIComponent(serveFileHtmlUrl)}`
        const link = document.createElement('a');
        link.href = cyberpdfUrl;
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
    }

    downloadFile(signedUrl) {
        const link = document.createElement('a');
        link.href = signedUrl;
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
    }

    processReportExcel() {
        let tripReportHtmlExcel = "https://us-central1-cybertracsa01.cloudfunctions.net/tripreportexcel";

        let request = this.buildTripReportRequest(this.props)
        //console.log(request);

        axios({
            method: 'post',
            url: tripReportHtmlExcel,
            data: request
        })
        .then(resp => {
            let data = resp.data;
            if(data.Success === true) {
                if (data.NextAction === "retry") {
                    setTimeout(this.processReportExcel, 4000);
                } else {
                    this.setState(s => Object.assign(s, { excel_busy: false }));
                    this.downloadFile(data.SignedUrl);
                }
            }
            else {
                this.setState(s => Object.assign(s, { excel_busy: false }));
            }
        })
        .catch(err => {
            console.log(err);
            this.setState(s => Object.assign(s, { excel_busy: false }));
        });
    }



    processReportHtml() {

        let tripReportHtmlURL = "https://us-central1-cybertracsa01.cloudfunctions.net/tripreporthtml";

        let request = this.buildTripReportRequest(this.props)
        console.log(request);

        axios({
            method: 'post',
            url: tripReportHtmlURL,
            data: request
        })
        .then(resp => {
            let data = resp.data;
            if(data.Success === true) {
                if (data.NextAction === "retry") {
                    setTimeout(this.processReportHtml, 4000);
                } else {
                    this.setState(s => Object.assign(s, { busy: false }));
                    this.renderAndDownloadPDFFile(data.FileKey);
                }
            }
            else {
                this.setState(s => Object.assign(s, { busy: false }));
            }
        })
        .catch(err => {
            console.log(err);
            this.setState(s => Object.assign(s, { busy: false }));
        });
    }

    click() {
        if(this.state.busy === true){
            return;
        }
        this.setState(s => Object.assign(s, { busy: true }));
        this.processReportHtml();
    }

    clickExcel() {
        if(this.state.excel_busy === true){
            return;
        }
        this.setState(s => Object.assign(s, { excel_busy: true }));
        this.processReportExcel();
    }

    render() {
        return (
            <>
                {/*<Button variant="secondary" onClick={this.click.bind(this)}>
                    {this.state.busy? 
                        <>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            <span> Preparing...</span>
                        </> 
                        :
                        <span>Download PDF</span>
                    }
                </Button>
                <span>&nbsp;</span>*/}
                <Button variant="warning" onClick={this.clickExcel.bind(this)}>
                {this.state.excel_busy? 
                    <>
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                        <span> Preparing...</span>
                    </> 
                    :
                    <span>Download Excel</span>
                }
            </Button> 
        </>);
    }
}



const formatStartDate = (d) => {
    let month = (d.getMonth()+1 < 10)? `0${d.getMonth()+1}`: `${d.getMonth()+1}`
    let day = (d.getDate() < 10)? `0${d.getDate()}`:`${d.getDate()}`;
    return `${d.getFullYear()}-${month}-${day} 00:00:00`;
}

const formatEndDate = (d) => {
    let month = (d.getMonth()+1 < 10)? `0${d.getMonth()+1}`: `${d.getMonth()+1}`
    let day = (d.getDate() < 10)? `0${d.getDate()}`:`${d.getDate()}`;
    return `${d.getFullYear()}-${month}-${day} 23:59:59`;
}

const formatDate = (d) => {
    let month = (d.getMonth()+1 < 10)? `0${d.getMonth()+1}`: `${d.getMonth()+1}`
    let day = (d.getDate() < 10)? `0${d.getDate()}`:`${d.getDate()}`;
    return `${d.getFullYear()}-${month}-${day}`;
}

const getReportDate = () => {
    let d = new Date();
    return `${formatDate(d)} ${d.toLocaleTimeString()}`;
}

// https://api.cybertracsa.co.za/history2?trackerid=0604961402&from=2019-08-26%2000:00:00&to=2019-08-27%2023:59:59

// http://192.168.101.211:8080/history2?trackerid=0814688891%20&from=2019-01-02%2000:00:00&to=2019-01-03%2000:00:00


// https://us-central1-cybertracsa01.cloudfunctions.net/tripreportserve?fileRequestKey=19823791827-2019-01-12-2019-01-13


export default DownloadReportButton;

// {
//     "client": {
//       "ClientID": -1,
//       "Username": "nishkarr",
//       "Fullname": "Nishkar Ramautar",
//       "IsAdmin": true,
//       "Vehicles": [
//         {
//           "Registration": "ND203539",
//           "Make": "AUDI",
//           "Model": "A4",
//           "Year": 2008,
//           "Color": "silver",
//           "ChassisNumber": "WAUZZZ8E05A4450363",
//           "TrackerID": "0786976405",
//           "ClientName": "KARSON NAIDOO",
//           "ClientID": 1
//         },
//         {
//           "Registration": "NJ38449",
//           "Make": "HYUNDAI",
//           "Model": "I 10 GRAND",
//           "Year": 2018,
//           "Color": "POLAR WHITE",
//           "ChassisNumber": "MALA851ALJM826042",
//           "TrackerID": "0738393136",
//           "ClientName": "ASH DAYLA",
//           "ClientID": 2
//         },
//         {
//           "Registration": "ND619294",
//           "Make": "VOLKSWAGEN",
//           "Model": "Polo 1.0 TSI Comfortline",
//           "Year": 2018,
//           "Color": "ORANGE",
//           "ChassisNumber": "AAVZZZGRZFU058631",
//           "TrackerID": "34621003",
//           "ClientName": "Nishkar Ramautar",
//           "ClientID": 3
//         },
//         {
//           "Registration": "ND802019",
//           "Make": "CHEVROLET",
//           "Model": "Corsa Utility",
//           "Year": 2010,
//           "Color": "WHITE",
//           "ChassisNumber": "N/A",
//           "TrackerID": "0604961402",
//           "ClientName": "Nishkar Ramautar",
//           "ClientID": 3
//         },
//         {
//           "Registration": "ND800808",
//           "Make": "VOLKSWAGEN",
//           "Model": "Amarok",
//           "Year": 2015,
//           "Color": "White",
//           "ChassisNumber": "Aawwwwwwww1233456",
//           "TrackerID": "0687815072010862",
//           "ClientName": "Rishi Manichand",
//           "ClientID": 4
//         },
//         {
//           "Registration": "ND106448",
//           "Make": "BMW",
//           "Model": "320i",
//           "Year": 2007,
//           "Color": "Black",
//           "ChassisNumber": "Wda1234567890",
//           "TrackerID": "0814179553",
//           "ClientName": "Rishi Manichand",
//           "ClientID": 4
//         },
//         {
//           "Registration": "ND80307",
//           "Make": "VOLKSWAGEN",
//           "Model": "POLO VIVO",
//           "Year": 2014,
//           "Color": "SILVER",
//           "ChassisNumber": "AAVBBBGRZJH7058631",
//           "TrackerID": "0814517787",
//           "ClientName": "Rishi Manichand",
//           "ClientID": 4
//         },
//         {
//           "Registration": "TRAILERDEMO",
//           "Make": "BMW",
//           "Model": "335i",
//           "Year": 2017,
//           "Color": "Blue",
//           "ChassisNumber": "AAVBBBGRZJH7053736631",
//           "TrackerID": "8161258245",
//           "ClientName": "Rishi Manichand",
//           "ClientID": 4
//         },
//         {
//           "Registration": "ND81793",
//           "Make": "BMW",
//           "Model": "318i",
//           "Year": 1991,
//           "Color": "White",
//           "ChassisNumber": "OAC96754",
//           "TrackerID": "0813039307",
//           "ClientName": "Nithesh Sonlall",
//           "ClientID": 5
//         },
//         {
//           "Registration": "ND67733",
//           "Make": "MAZDA",
//           "Model": "Bantam",
//           "Year": 2001,
//           "Color": "White",
//           "ChassisNumber": "AFAWXDL01WR390668",
//           "TrackerID": "0813572140",
//           "ClientName": "Fahim Khan",
//           "ClientID": 6
//         },
//         {
//           "Registration": "NUR31469",
//           "Make": "RENAULT",
//           "Model": "Stepway",
//           "Year": 2012,
//           "Color": "White",
//           "ChassisNumber": "ADRBSRAFK47713730",
//           "TrackerID": "0814700029",
//           "ClientName": "Strigan Pather",
//           "ClientID": 7
//         },
//         {
//           "Registration": "ND501523",
//           "Make": "VOLKSWAGEN",
//           "Model": "GOLF 7 GTi",
//           "Year": 2015,
//           "Color": "Limestone Grey",
//           "ChassisNumber": "WVWZZZAUZFW328120",
//           "TrackerID": "0814931912",
//           "ClientName": "Keith Pillay",
//           "ClientID": 8
//         },
//         {
//           "Registration": "LORATESTGP",
//           "Make": "RENAULT",
//           "Model": "Clio RS",
//           "Year": 2017,
//           "Color": "Yellow",
//           "ChassisNumber": "23452345234534523",
//           "TrackerID": "999001",
//           "ClientName": "LORA TEST1",
//           "ClientID": 9
//         },
//         {
//           "Registration": "ND80307",
//           "Make": "VOLKSWAGEN",
//           "Model": "POLO VIVO",
//           "Year": 2012,
//           "Color": "SILVER",
//           "ChassisNumber": "AAVZZZ6SZAU014214",
//           "TrackerID": "0814517787",
//           "ClientName": "YASEEN KHAN",
//           "ClientID": 10
//         },
//         {
//           "Registration": "ND806435",
//           "Make": "VOLKSWAGEN",
//           "Model": "POLO 6 GTi",
//           "Year": 2015,
//           "Color": "WHITE",
//           "ChassisNumber": "WVWZZZ6RZGY079466",
//           "TrackerID": "8161258248",
//           "ClientName": "YASEEN KHAN",
//           "ClientID": 10
//         },
//         {
//           "Registration": "ND40290",
//           "Make": "TOYOTA",
//           "Model": "AVANZA",
//           "Year": 2012,
//           "Color": "WHITE",
//           "ChassisNumber": "MHFM1BH1N8K005725",
//           "TrackerID": "0765936151",
//           "ClientName": "JULIUS GOVENDER",
//           "ClientID": 11
//         },
//         {
//           "Registration": "ND621490",
//           "Make": "TOYOTA",
//           "Model": "COROLLA",
//           "Year": 2008,
//           "Color": "MAROON",
//           "ChassisNumber": "AHT53ZEC107527102",
//           "TrackerID": "8161258269",
//           "ClientName": "FORD NAYAGER",
//           "ClientID": 12
//         },
//         {
//           "Registration": "ND867508",
//           "Make": "CHEVROLET",
//           "Model": "UTIILITY",
//           "Year": 2013,
//           "Color": "WHITE",
//           "ChassisNumber": "ADMCF80GJD4624046",
//           "TrackerID": "8161258285",
//           "ClientName": "STRATEGIX RESPONSE UNIT SA PTY LTD",
//           "ClientID": 13
//         },
//         {
//           "Registration": "NUZ39181",
//           "Make": "AUDI",
//           "Model": "A3",
//           "Year": 2016,
//           "Color": "WHITE",
//           "ChassisNumber": "WAUZZZ8VXGA002731",
//           "TrackerID": "34534271",
//           "ClientName": "ANDILE SYDWELL MDLETSHE",
//           "ClientID": 14
//         }
//       ]
//     },
//     "vehicle": {
//       "Registration": "ND802019",
//       "Make": "CHEVROLET",
//       "Model": "Corsa Utility",
//       "Year": 2010,
//       "Color": "WHITE",
//       "ChassisNumber": "N/A",
//       "TrackerID": "0604961402",
//       "ClientName": "Nishkar Ramautar",
//       "ClientID": 3
//     },
//     "startDate": "2019-08-31T10:28:46.309Z",
//     "endDate": "2019-08-31T10:28:46.309Z"
//   }
