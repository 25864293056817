import React, { Component } from "react"
import { Navigate } from 'react-router-dom'
import { Container, Row, Col, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap'
import DatePicker from "react-date-picker"
import ReportComponent from "./ReportComponent"
import DownloadReportButton from "./DownloadReportButton"
import VehicleSelector from "./VehicleSelector"
import { connect } from 'react-redux'

const colStyle = { display: "flex",
	justifyContent: "space-evenly",
	alignItems: "center" 
};

class ReportsPage extends Component {

	constructor(props) {
		super(props)
		this.state = {
			currentIndex: 0,
			dateRange: "lastday",
			startDate: new Date(),
			endDate: new Date(),
			viewReport: false,
			componentParams_startDate: null,
			componentParams_endDate: null,
			dropdownOpen: false
		}

		this.toggleDropdown.bind(this)
	}

	toggleDropdown = () => {
		this.setState(s => Object.assign(s, { dropdownOpen: !this.state.dropdownOpen }))
	}

	// selectVehicle = (index) =>{
	// 	if(index === this.state.currentIndex) {
	// 		return;
	// 	}

	// 	this.setState(s => {
	// 		return { currentIndex: index, viewReport: false }
	// 	})
	// }

	dateRangeName(dr) {
		switch(dr){
			case "lastday":
				return "Last Day";
			case "last2days":
				return "Last 2 Days";
			case "custom":
				return "Custom";
			default:
				return "Custom";
		}
	}

	changeDateRange(dr) {

		let startDate = new Date();
		let previousDate = new Date();
		previousDate.setDate(previousDate.getDate() - 1);

		if(dr === "lastday") {
			this.setState(s => Object.assign(s, { dateRange: dr, startDate: startDate, endDate: startDate, viewReport: false }));
			return;
		}
		if(dr === "last2days") {
			this.setState(s => Object.assign(s, { dateRange: dr, startDate: previousDate, endDate: startDate, viewReport: false }));
			return;
		}
		if(dr === "custom") {
			this.setState(s => Object.assign(s, { dateRange: dr, viewReport: false }));
		}
	}

	handleChangeStartDate(d) {
		this.setState(s => Object.assign(s, { startDate: d, viewReport: false }))
	}

	handleChangeEndDate(d){
		this.setState(s => Object.assign(s, { endDate: d, viewReport: false }))
	}


	viewReport() {
		// let cp = {
		// 	trackerid: this.props.vehicles && this.props.vehicles[this.state.currentIndex],
		// 	startDate: this.state.startDate,
		// 	endDate: this.state.endDate
		// }
		const {vehicle} = this.props;
		if(vehicle == null) {
			return;
		}

		this.setState(s => Object.assign(s, { componentParams_startDate: this.state.startDate, componentParams_endDate: this.state.endDate, viewReport: true }))
	}

	render() {
		const {authenticated, vehicle} = this.props;
		

		if (!authenticated) {
			return <Navigate to={{ pathname: '/login' }}/>
		}

		return (
			<div className="Page">
				<Container fluid>
					<VehicleSelector />
					<Row style={{ margin: "0.25em 0" }}>
						<Col style={colStyle} xs={4} lg={3}>
							Date Range: <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown}>
                                    		<DropdownToggle caret>
                                        		{ this.dateRangeName(this.state.dateRange) }
                                    		</DropdownToggle>
                                    		<DropdownMenu>
												<DropdownItem key="lastday" onClick={ () => { this.changeDateRange("lastday")} }>{this.dateRangeName("lastday")}</DropdownItem>
												<DropdownItem key="last2days" onClick={ () => { this.changeDateRange("last2days")} }>{this.dateRangeName("last2days")}</DropdownItem>
												<DropdownItem key="custom" onClick={ () => { this.changeDateRange("custom")} }>{this.dateRangeName("custom")}</DropdownItem>										
                                    		</DropdownMenu>
                                		</ButtonDropdown>
						</Col>
						<Col>
							&nbsp; 
							{this.state.dateRange === "custom" && (
								<>
									Start: <DatePicker
										calendarAriaLabel="Toggle calendar"
										clearAriaLabel="Clear value"
										dayAriaLabel="Day"
										monthAriaLabel="Month"
										nativeInputAriaLabel="Date"
								    	value={this.state.startDate}
										onChange={this.handleChangeStartDate.bind(this)}
										locale="en-GB"
									/>
									&nbsp; 
									End: <DatePicker
										calendarAriaLabel="Toggle calendar"
										clearAriaLabel="Clear value"
										dayAriaLabel="Day"
										monthAriaLabel="Month"
										nativeInputAriaLabel="Date"
								    	value={this.state.endDate}
								    	onChange={this.handleChangeEndDate.bind(this)}
									/>
									&nbsp; 
								</>
							)}
							<Button variant="success" onClick={this.viewReport.bind(this)}>View Report</Button>
							<span>&nbsp;</span>
							{ this.state.viewReport === true && <DownloadReportButton client={this.props.client} 
												  									  vehicle={vehicle} 
												  									  startDate={this.state.componentParams_startDate}
												  									  endDate={this.state.componentParams_endDate} /> }
						</Col>
					</Row>
				</Container>

				{this.state.viewReport === true && <ReportComponent trackerid={vehicle.TrackerID} 
																	startDate={this.state.componentParams_startDate}
																	endDate={this.state.componentParams_endDate} />}

			</div>
		);		
	}

}

const mapStateToProps = (state, ownProps) => {
	return {
		authenticated: state.auth.authenticated,
		vehicle: state.selectedVehicle,
		client: { Fullname: state.auth.user.Fullname, ClientID: state.auth.user.ClientID }
	}
}

export default connect(mapStateToProps)(ReportsPage);