import React, { useState } from 'react'
import { Container, 
         Row, 
         Col, 
         ButtonDropdown, 
         DropdownToggle, 
         DropdownMenu, 
         DropdownItem,
         Alert } from 'reactstrap'
import { connect } from 'react-redux'
import { changeSelectedVehicle } from '../redux/actions/selectedVehicleActions'

import './VehicleSelector.scss'

const colStyle = {
	display: "flex",
	justifyContent: "space-evenly",
	alignItems: "center"
};



const VehicleSelector = ({vehicles, selectedVehicle, onSelectVehicle}) => {
    const [dropdownOpen, setOpen] = useState(false);
    const toggle = () => setOpen(!dropdownOpen);

    const dropDownTitle = selectedVehicle? selectedVehicle.Registration : 
                                    (vehicles? "Select vehicle" : "No vehicles")

    return (
        <Container fluid className="vehicle-selector">
            <Row style={{ margin: "0.25em 0" }}>
                <Col style={colStyle} xs={4} lg={3}>
                    Vehicle: { <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
                                    <DropdownToggle caret>
                                        { dropDownTitle }
                                    </DropdownToggle>
                                    <DropdownMenu /*modifiers={ sizeModifiers }*/>
                                    { vehicles.map((v, i) => 
                                        <DropdownItem onClick={() => { onSelectVehicle(v) }} 
                                                        key={`${i}${v.Registration}`}>{v.Registration} {v.Make} {v.Model}
                                        </DropdownItem>)
                                    }
                                    </DropdownMenu>
                                </ButtonDropdown>
                             }
                </Col>
                <Col>
                    {selectedVehicle && <Alert style={{ backgroundColor: "unset" }} variant="dark">{selectedVehicle.Year} {selectedVehicle.Make} {selectedVehicle.Model} </Alert>}
                </Col>
            </Row>
        </Container>
    )    
}

const mapStateToProps = (state) => ({
    vehicles: state.auth.user.Vehicles,
    selectedVehicle: state.selectedVehicle
})

const mapDispatchToProps = (dispatch) => ({
    onSelectVehicle: (v) => dispatch(changeSelectedVehicle(v))
})

export default connect(mapStateToProps, mapDispatchToProps)(VehicleSelector)

